<template>
  <div>
    <div class="d-flex align-center mb-4 gap-4">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" to="/imoveis" icon>
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </template>
        <span>Voltar</span>
      </v-tooltip>
      <h3 class="mb-0">Relatório</h3>
    </div>
    <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
    <div class="d-flex flex-column" style="gap: 0.7rem" v-if="!loading && !error">
      <Header :immobile="immobile" />
      <Rentals :immobile="immobile" />
      <v-card outlined class="pa-4">
        <h4>Vistorias</h4>
        <inspection-table :inspections="immobile.Inspection" />
      </v-card>
    </div>
  </div>
</template>

<script>
import IMMOBILE from '@/services/immobile'
import Header from './modules/immobile/Header.vue'
import Rentals from './modules/immobile/Rentals.vue'
import InspectionTable from '../global/InspectionTable.vue'

export default {
  components: { Header, Rentals, InspectionTable },
  name: 'ImmobileReport',
  data() {
    return {
      loading: true,
      error: false,
      immobile: {},
    }
  },
  methods: {
    async getImmobile() {
      try {
        this.loading = true
        const { immobile } = await IMMOBILE.get(this.$route.params.id)
        this.immobile = immobile
        this.loading = false
      } catch (e) {
        console.log(e)
        this.$toast.fire({
          icon: 'error',
          title: e.message || 'Erro ao buscar imóvel',
        })
        this.$router.push({ name: 'immobile' })
      }
    },
  },
  mounted() {
    this.getImmobile()
  },
}
</script>

<style></style>
