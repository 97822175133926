<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title class="justify-center">
        <span class="text-h5">Gerar Contrato</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form v-model="valid" submit.prevent>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="contract.payday"
                  label="Data Pagamento"
                  prepend-icon="mdi-calendar"
                  type="number"
                  :rules="[
                    v => !!v || 'Campo obrigatório',
                    v => v > 0 || 'Valor deve ser maior que 0',
                    v => v <= 28 || 'Valor deve ser menor que 28',
                  ]"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field-money
                  v-model="contract.value"
                  label="Valor"
                  :properties="{
                    prefix: 'R$',
                    outlined: true,
                    dense: true,
                    placeholder: ' ',
                    hideDetails: true,
                    rules: contractValueRules,
                  }"
                  v-bind:options="{
                    locale: 'pt-BR',
                    length: 11,
                    precision: 2,
                    empty: '0',
                  }"
                />
              </v-col>

              <v-col cols="12">
                <v-select
                  v-model="contract.iptuInclude"
                  label="IPTU"
                  :items="[
                    { text: 'Locatário', value: true },
                    { text: 'Locador', value: false },
                  ]"
                  :rules="[v => !!v || 'Campo obrigatório']"
                  outlined
                  dense
                  hide-details
                >
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="contract.readjustment"
                  label="Reajuste"
                  :items="readjustmentTypes"
                  outlined
                  dense
                  hide-details
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-dialog ref="dialog" v-model="modalStart" persistent width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="$options.filters.formatDate(contract.startDate)"
                      label="Início"
                      prepend-icon="mdi-calendar"
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                      :rules="[v => !!v || 'Campo obrigatório']"
                      hide-details
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker locale="pt-br" @change="selectStartDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modalStart = false">Fechar</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="duration" label="Duração" type="number" :messages="[endDate]" outlined dense />
                <div v-if="false">
                  <v-dialog ref="dialog" v-model="modalEnd" persistent width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="$options.filters.formatMonth(contract.endDate)"
                        label="Encerramento"
                        prepend-icon="mdi-calendar"
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                        :rules="[v => !!v || 'Campo obrigatório']"
                        :messages="duration"
                        readonly
                      ></v-text-field>
                    </template>
                    <v-date-picker locale="pt-br" type="month" :min="minMonth" @change="selectEndDate" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modalEnd = false">Fechar</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions class="px-8 pb-8">
        <v-spacer></v-spacer>
        <v-btn color="primary" class="mr-2 mt-2" outlined :disabled="loading" @click="close">Cancelar</v-btn>
        <v-btn color="primary" class="mt-2" :loading="loading" :disabled="!valid" @click="save">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RENTAL from '@/services/rental'
import moment, { duration } from 'moment'
const defaultData = () => ({
  payday: 10,
  value: null,
  iptuInclude: true,
  readjustment: 'IPCA',
  startDate: null,
  endDate: null,
})
export default {
  data() {
    return {
      loading: false,
      dialog: false,
      valid: false,
      duration: 12,
      rental: {},
      contract: defaultData(),
      contractValueRules: [
        v => !!v || 'Campo obrigatório',
        v => parseFloat(v.replace('.', '').replace(',', '.')) > 1 || 'Valor deve ser maior que 1',
      ],
      readjustmentTypes: ['IPCA', 'IGPM', 'INCC', 'SELIC'],
      modalStart: false,
      modalEnd: false,
    }
  },
  methods: {
    async save() {
      try {
        this.loading = true
        let obj = Object.assign({}, this.contract)
        obj.startDate = moment(obj.startDate).toISOString()
        obj.endDate = moment(this.endDate, 'DD/MM/YYYY').set('date', moment(obj.startDate).date()).toISOString()
        await RENTAL.contract.create(this.rental.id, obj)
        this.$emit('success')
        this.close()
      } catch (error) {
        console.log(error)
        this.$toast.fire({
          icon: 'error',
          title: error.message || 'Erro ao salvar imóvel',
        })
        this.$emit('error', error)
        this.loading = false
      }
    },

    selectStartDate(date) {
      this.contract.startDate = date
      this.modalStart = false
    },
    selectEndDate(date) {
      this.contract.endDate = date
      this.modalEnd = false
    },

    open({ rental, ...data }) {
      this.dialog = true
      this.rental = rental
      this.contract = Object.assign(defaultData(), data)
    },
    close() {
      this.loading = false
      this.dialog = false
      this.immobile = defaultData()
    },
  },
  computed: {
    minMonth() {
      return moment(this.contract.startDate || new Date())
        .add(1, 'month')
        .toISOString()
    },
    endDate() {
      const startDate = this.contract.startDate
      const endDate = moment(startDate).add(this.duration, 'months')
      return endDate.format('DD/MM/YYYY')
    },
    // duration() {
    //   const contract = this.contract
    //   if (!contract.startDate || !contract.endDate) return ''
    //   let start = moment(contract.startDate)
    //   let end = moment(contract.endDate)
    //   let months = end.diff(start, 'months')
    //   return `${months} meses`
    // },
  },
  watch: {
    'contract.startDate'(value) {
      if (!value) return
      this.contract.endDate = moment(value).add(1, 'month').toISOString()
    },
  },
  filters: {
    formatDate(value) {
      if (!value) return ''
      return moment(value).format('DD/MM/YYYY')
    },
    formatMonth(value) {
      if (!value) return ''
      return moment(value).format('MM/YYYY')
    },
  },
  created() {
    this.$parent.$on(this.componentName, this.open)
  },
  props: {
    componentName: {
      type: String,
      default: 'contract-modal',
    },
  },
}
</script>

<style></style>
