<template>
  <v-container fluid>
    <v-row>
      <!-- Left Side: Map -->
      <v-col cols="8">
        <div style="position: relative" class="rounded-xl">
          <div id="map" style="height: calc(100vh - 100px)" class="rounded-xl"></div>

          <v-btn
            @click="$emit('display-list')"
            class="rounded-circle"
            fab
            color="primary"
            style="position: absolute; bottom: 20px; left: 20px"
          >
            <v-icon>mdi-format-list-bulleted</v-icon>
          </v-btn>
        </div>
      </v-col>

      <!-- Right Side: Empty Space -->
      <v-col cols="4">
        <div class="d-flex flex-column">
          <v-card class="rounded-xl">
            <v-card-title v-if="!selectedImmobile">Nenhuma seleção</v-card-title>
            <v-card-title v-else class="d-block">
              <div class="d-flex justify-space-between">
                <span>{{ selectedImmobile.Address.street }}, {{ selectedImmobile.Address.number }}</span>
                <v-chip :color="statusChip[selectedImmobile.status].color" small outlined label>
                  {{ statusChip[selectedImmobile.status].text }}
                </v-chip>
              </div>
              <small class="d-block mt-1">
                {{ selectedImmobile.Address.city }}/{{ selectedImmobile.Address.state }}
              </small>
            </v-card-title>
            <v-card-text v-if="!selectedImmobile">
              <v-alert type="info" class="mb-0"> Clique em um imóvel no mapa para ver mais detalhes. </v-alert>
            </v-card-text>
            <v-card-text v-else>
              <p class="mb-1">
                Tipo: <b>{{ selectedImmobile.type }}</b>
              </p>
              <p class="mb-1">
                Descrição: <b>{{ selectedImmobile.description }}</b>
              </p>
              <p class="mb-1">
                IPTU: <b>{{ selectedImmobile.iptuRegistry }}</b>
              </p>
              <p class="mb-1">
                Registro: <b>{{ selectedImmobile.registry }}</b>
              </p>
              <v-divider class="my-2"></v-divider>
              <div class="d-flex gap-2">
                <TooltipedBtn
                  v-if="hasPermission(64)"
                  @click="immobileReport(selectedImmobile)"
                  :disabled="loading"
                  title="Relatório"
                  class="mr-4"
                  icon
                >
                  <v-icon> mdi-file-document-outline </v-icon>
                </TooltipedBtn>
                <TooltipedBtn
                  v-if="hasPermission(64)"
                  @click="editImmobile(selectedImmobile)"
                  :disabled="loading"
                  title="Editar"
                  class="mr-4"
                  icon
                >
                  <v-icon> mdi-pencil-outline </v-icon>
                </TooltipedBtn>
                <TooltipedBtn v-if="hasPermission(64)" title="Anexos" @click="attachments(selectedImmobile)" icon>
                  <v-icon> mdi-paperclip</v-icon>
                </TooltipedBtn>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    immobiles: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      map: null,
      selectedImmobile: null,
      statusChip: {
        rented: { text: 'Alugado', color: 'success' },
        reserved: { text: 'Reservado', color: 'warning' },
        inactive: { text: 'Inativo', color: 'error' },
        available: { text: 'Disponível', color: 'accent' },
      },
    }
  },
  mounted() {
    if (typeof google === 'undefined') {
      const script = document.createElement('script')
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_MAPS_TOKEN}`
      document.head.appendChild(script)
      script.onload = () => {
        this.initMap()
      }
    } else {
      this.initMap()
    }
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
  },
  methods: {
    initMap() {
      // Centro do mapa na localização do primeiro imóvel
      const center = { lat: -23.3045, lng: -51.1696 }

      // Inicializa o mapa no modo satélite
      this.map = new google.maps.Map(document.getElementById('map'), {
        center: center,
        zoom: 13,
        mapTypeId: google.maps.MapTypeId.SATELLITE,
      })

      this.addMarkers()
    },

    // Novo método para adicionar marcadores
    addMarkers() {
      this.markers = this.immobiles.map(immobile => {
        const position = {
          lat: parseFloat(immobile.Address.lat),
          lng: parseFloat(immobile.Address.lng),
        }
        const marker = new google.maps.Marker({
          position,
          map: this.map,
          title: immobile.description,
        })

        marker.addListener('click', () => {
          this.selectedImmobile = immobile
        })

        return marker
      })
    },

    // Novo método para remover marcadores
    removeMarkers() {
      if (this.markers) {
        this.markers.forEach(marker => marker.setMap(null))
        this.markers = []
      }
    },

    editImmobile(item) {
      this.$root.$emit('immobile-modal', item)
    },
    attachments(item) {
      this.$root.$emit('immobile-attachment', item)
    },
    immobileReport(item) {
      this.$router.push({ name: 'immobile.report', params: { id: item.id } })
    },
  },
}
</script>

<style scoped>
#map {
  width: 100%;
  height: 100%;
}
</style>
