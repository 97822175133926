<template>
  <component :is="resolveLayout">
    <router-view></router-view>
  </component>
</template>

<script>
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'

export default {
  components: {
    LayoutBlank,
    LayoutContent,
  },
  computed: {
    resolveLayout() {
      if (!this.$route?.name) return null
      if (this.$route?.meta?.layout === 'blank') return 'layout-blank'
      return 'layout-content'
    },
  },
}
</script>

<style lang="scss">
.unselectable {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
}

.non-draggable {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

@for $i from 1 through 16 {
  .lh-#{$i} {
    line-height: #{1 + (($i - 1) * 0.1)}rem !important;
  }
}

.v-expansion-panels.outline {
  div.v-expansion-panel {
    border: 2px solid rgba(0, 0, 0, 0.12);
    transition: border-width 1s ease;
    &:not(:first-child) {
      border-top: none; // Remove a borda superior
    }

    &.v-expansion-panel--active {
      border: 2px solid rgba(0, 0, 0, 0.12);
    }
  }
}

@for $i from 0 through 16 {
  .gap-x-#{$i} {
    column-gap: #{$i * 0.25}rem;
  }
  .gap-y-#{$i} {
    row-gap: #{$i * 0.25}rem;
  }

  .gap-#{$i} {
    gap: #{$i * 0.25}rem;
  }
}
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
</style>