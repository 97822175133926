<template>
  <v-tooltip v-bind="tooltipProps">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="{
          ...attrs,
          ...btnProps,
        }"
        v-on="on"
        @click="$emit('click')"
      >
        <slot />
      </v-btn>
    </template>
    <span>{{ title }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'TooltipedBtn',
  props: {
    tooltipProps: {
      type: Object,
      default: () => ({ top: true }),
    },
    title: {
      type: String,
      default: 'Visualizar',
    },
    icon: Boolean,
    small: Boolean,
    large: Boolean,
    xLarge: Boolean,
    disabled: Boolean,
    loading: Boolean,
    color: String,
    dark: Boolean,
    light: Boolean,
    outlined: Boolean,
    rounded: Boolean,
    text: Boolean,
    tile: Boolean,
    block: Boolean,
    fab: Boolean,
    depressed: Boolean,
    to: Object | String,
  },
  computed: {
    btnProps() {
      return {
        icon: this.icon,
        small: this.small,
        large: this.large,
        xLarge: this.xLarge,
        disabled: this.disabled,
        loading: this.loading,
        color: this.color,
        dark: this.dark,
        light: this.light,
        outlined: this.outlined,
        rounded: this.rounded,
        text: this.text,
        tile: this.tile,
        block: this.block,
        fab: this.fab,
        depressed: this.depressed,
        to: this.to,
      }
    },
  },
}
</script>

<style>
</style>