<template>
  <div>
    <v-card :loading="loading">
      <v-card-text class="px-8 pt-3">
        <v-card-title class="px-0 pt-0">
          <v-text-field
            class="pt-0"
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar..."
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="filteredClients"
          :search="search"
          :header-props="{ sortByText: 'Ordenar por:' }"
          :sort-desc="false"
          sort-by="fullname"
          :items-per-page="itemsPerPage"
          :page="page"
          @update:page="page = $event"
          @update:items-per-page="itemsPerPage = $event"
          hide-default-footer
        >
          <template v-slot:top>
            <div flat class="d-flex justify-space-between">
              <v-btn
                color="primary"
                dark
                class="mb-4"
                style="margin-left: -17px"
                @click="$emit('client-modal')"
                v-if="hasPermission(512)"
              >
                Adicionar Cliente
              </v-btn>

              <v-btn-toggle v-model="filterType" color="success">
                <v-btn v-for="(type, index) in ['active', 'inactive']" :key="index" :value="type" depressed>
                  {{ type === 'active' ? 'Ativos' : 'Inativos' }}
                </v-btn>
              </v-btn-toggle>
            </div>
          </template>
          <template v-slot:[`item.email`]="{ item }">
            <a :href="`mailto:${item.email}`" class="text--secondary text-decoration-none">{{ item.email }}</a>
          </template>
          <template v-slot:[`item.phone`]="{ item }">
            <a
              v-if="item.phone"
              :href="`tel:0${item.phone.replace(/\D/g, '')}`"
              class="text--secondary text-decoration-none"
            >
              {{ item.phone }}
            </a>
          </template>
          <template v-slot:[`item.active`]="{ item }">
            <v-chip :color="item.active ? 'success' : 'error'" outlined label small>
              {{ item.active ? 'Ativo' : 'Inativo' }}
            </v-chip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <TooltipedBtn title="Editar" @click="editClient(item)" icon v-if="hasPermission(1024)">
              <v-icon>mdi-pencil-outline</v-icon>
            </TooltipedBtn>
            <TooltipedBtn title="Anexos" @click="clientAttachment(item)" icon v-if="hasPermission(1024)">
              <v-icon>mdi-attachment</v-icon>
            </TooltipedBtn>
          </template>
          <template v-slot:no-results>
            <v-btn color="primary" @click="search = ''">Resetar</v-btn>
          </template>
          <template v-slot:footer>
            <v-pagination v-model="page" :length="Math.ceil(clients.length / itemsPerPage)"></v-pagination>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <client-modal @success="getClients" />
    <client-attachment-modal @success="getClients" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CLIENT from '@/services/client'
import ClientModal from '@/components/modals/ClientModal'
import ClientAttachmentModal from '@/components/modals/ClientAttachmentModal'
export default {
  components: { ClientModal, ClientAttachmentModal },
  data: () => ({
    clients: [],
    loading: false,
    search: '',
    page: 1,
    itemsPerPage: 5,
    filterType: 'active',
    headers: [
      { text: 'Tipo de Pessoa', value: 'type', sortable: true, align: 'start' },
      { text: 'Nome', value: 'name', sortable: true },
      { text: 'E-mail', value: 'email', sortable: false },
      { text: 'Telefone', value: 'phone', sortable: false },
      { text: 'Status', value: 'active' },
      { text: 'Ações', value: 'actions', sortable: false, align: 'end', width: '100' },
    ],
  }),

  methods: {
    clientAttachment(client) {
      this.$emit('client-attachment', client)
    },
    editClient(client) {
      this.$emit('client-modal', client)
    },
    async getClients() {
      try {
        this.loading = true
        const { clients } = await CLIENT.getAll()
        this.clients = clients
        this.loading = false
      } catch (e) {
        console.log(e)
        this.$toast.fire({
          icon: 'error',
          title: e.message || 'Erro ao buscar clientes',
        })
        this.$router.push({ name: 'dashboard' })
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
    filteredClients() {
      return this.clients.filter(client => {
        if (this.filterType === 'active') return client.active
        if (this.filterType === 'inactive') return !client.active
        return true
      })
    },
  },

  created() {
    this.getClients()
  },
}
</script>

<style lang="scss" scoped>
.v-card__title {
  text-align: center !important;
  display: inherit;
}
</style>
