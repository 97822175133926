<template>
  <div>
    <v-dialog v-model="dialog" max-width="800px" :persistent="loading">
      <v-card>
        <v-card-title class="d-flex justify-space-between align-center">
          <span class="text-h5">Anexos do Imóvel</span>
          <v-btn @click="add" color="primary" small :disabled="loading"> Adicionar Anexo </v-btn>
        </v-card-title>
        <v-card-text v-if="client">
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="client.Attachments"
              :search="search"
              :header-props="{ sortByText: 'Ordenar por:' }"
              :sort-desc="true"
              sort-by="date"
              hide-default-footer
            >
              <template v-slot:top>
                <v-text-field
                  dense
                  outlined
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Pesquisar..."
                  hide-details
                  class="mb-1"
                />
              </template>
              <template v-slot:[`item.size`]="{ item }">
                {{ item.size | fileSize }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon class="mr-4" @click="goToURL(item)" :disabled="loading">mdi-tray-arrow-down</v-icon>
                <v-icon @click="deleteAttachment(item)" :disabled="loading">mdi-delete-outline</v-icon>
              </template>
              <template v-slot:no-results>
                <v-btn color="primary" @click="search = ''">Resetar</v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card-text>
        <v-card-actions class="px-8 pb-8">
          <v-spacer></v-spacer>
          <v-btn color="primary" class="mr-2 mt-2" outlined @click="close" :disabled="loading">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <client-attachment-upload-modal :client="client" @success="pushAttachment"></client-attachment-upload-modal>
    <client-attachment-delete :client="client" @success="removeAttachment"></client-attachment-delete>
  </div>
</template>

<script>
import ClientAttachmentDelete from './ClientAttachmentDelete.vue'
import ClientAttachmentUploadModal from './ClientAttachmentUploadModal.vue'
export default {
  components: { ClientAttachmentUploadModal, ClientAttachmentDelete },
  data: () => ({
    dialog: false,
    loading: false,
    client: null,
    search: '',
    headers: [
      { text: 'Nome', value: 'name' },
      { text: 'Tamanho', value: 'size' },
      { text: 'Ações', value: 'actions', sortable: false, align: 'end' },
    ],
  }),
  methods: {
    removeAttachment(attachment) {
      this.client.Attachments = this.client.Attachments.filter(a => a.id !== attachment.id)
    },
    deleteAttachment(attachment) {
      this.$emit('delete-attachment', attachment)
    },
    goToURL(attachment) {
      window.open(attachment.url, '_blank')
    },
    pushAttachment(attachment) {
      this.client.Attachments.push(attachment)
    },
    add() {
      this.$emit('add-attachment')
    },
    open(client) {
      this.client = client
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.loading = false
      this.client = null
    },
  },
  filters: {
    fileSize(bytes) {
      let si = false,
        dp = 1
      const thresh = si ? 1000 : 1024

      if (Math.abs(bytes) < thresh) {
        return bytes + ' B'
      }

      const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
      let u = -1
      const r = 10 ** dp

      do {
        bytes /= thresh
        ++u
      } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

      return bytes.toFixed(dp) + ' ' + units[u]
    },
  },
  mounted() {
    this.$parent.$on('client-attachment', this.open)
  },
}
</script>

<style></style>
