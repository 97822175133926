<template>
  <div>
    <immobiles-map
      v-if="displayType == 'map'"
      :immobiles="immobiles"
      @display-list="displayType = 'list'"
      :loading="loading"
    />
    <v-card :loading="loading" v-if="displayType == 'list'">
      <v-card-text class="px-8 pt-3">
        <div class="d-flex gap-2">
          <v-text-field
            class="pt-0"
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar..."
            single-line
          ></v-text-field>
          <v-btn @click="displayType = 'map'" icon>
            <v-icon>mdi-map</v-icon>
          </v-btn>
        </div>

        <v-data-table :headers="headers" :items="result">
          <template v-slot:top>
            <v-toolbar flat>
              <v-btn
                color="primary"
                :disabled="loading"
                @click="addImmobile"
                dark
                class="mb-4 mt-3"
                style="margin-left: -17px"
                v-if="hasPermission(32)"
              >
                Adicionar Imóvel
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:[`item.ownerId`]="{ item }">
            <div>{{ item.ownerId ? 'Terceiro' : 'Próprio' }}</div>
          </template>
          <template v-slot:[`item.Address`]="{ item }">
            {{ item.Address.street }}, {{ item.Address.number }} - {{ item.Address.city }}/{{ item.Address.state }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip :color="statusChip[item.status].color" small outlined label>
              {{ statusChip[item.status].text }}
            </v-chip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex">
              <TooltipedBtn
                v-if="hasPermission(64)"
                @click="immobileReport(item)"
                :disabled="loading"
                title="Relatório"
                class="mr-4"
                icon
              >
                <v-icon> mdi-file-document-outline </v-icon>
              </TooltipedBtn>
              <TooltipedBtn
                v-if="hasPermission(64)"
                @click="editImmobile(item)"
                :disabled="loading"
                title="Editar"
                class="mr-4"
                icon
              >
                <v-icon> mdi-pencil-outline </v-icon>
              </TooltipedBtn>
              <TooltipedBtn title="Anexos" @click="attachments(item)" icon>
                <v-icon> mdi-paperclip</v-icon>
              </TooltipedBtn>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <immobile-modal @success="getImmobiles" />
    <immobile-attachment-modal />
  </div>
</template>

<script>
import IMMOBILE from '@/services/immobile'
import ImmobileModal from '@/components/modals/ImmobileModal.vue'
import { mapActions, mapGetters } from 'vuex'
import ImmobileAttachmentModal from '@/components/modals/ImmobileAttachmentModal.vue'
import Fuse from 'fuse.js'
import ImmobilesMap from '@/components/immobiles/ImmobilesMap.vue'

export default {
  components: { ImmobileModal, ImmobileAttachmentModal, ImmobilesMap },
  data: () => ({
    loading: false,
    search: '',
    displayType: 'list',

    immobiles: [],
    fuseInstance: null,
    result: [],

    headers: [
      { text: 'Imóvel', value: 'ownerId', sortable: true, align: 'start', width: '100' },
      { text: 'Tipo', value: 'type', sortable: true, width: '150' },
      { text: 'Endereço', value: 'Address', sortable: true },
      { text: 'Registro', value: 'registry', sortable: false },
      { text: 'IPTU', value: 'iptuRegistry', sortable: false },
      { text: 'Status', value: 'status', sortable: false },
      { text: 'Ações', value: 'actions', sortable: false, align: 'center', width: '100' },
    ],
    statusChip: {
      rented: { text: 'Alugado', color: 'success' },
      reserved: { text: 'Reservado', color: 'warning' },
      inactive: { text: 'Inativo', color: 'error' },
      available: { text: 'Disponível', color: 'accent' },
    },
  }),
  methods: {
    async getImmobiles() {
      try {
        this.loading = true
        const { immobiles } = await IMMOBILE.getAll()
        this.immobiles = immobiles
        this.loading = false

        this.initFuse()
      } catch (e) {
        console.log(e)
        this.$toast.fire({
          icon: 'error',
          title: e.message || 'Erro ao buscar imóveis',
        })
        this.$router.push({ name: 'dashboard' })
      }
    },
    initFuse() {
      this.fuseInstance = new Fuse(this.immobiles, {
        keys: [
          'type',
          'Address.street',
          'Address.city',
          'Address.state',
          'Address.number',
          'Address.complement',
          'registry',
          'iptuRegistry',
          'status',
          'Address.zipCode',
        ],
      })
      this.result = this.immobiles
    },
    addImmobile() {
      this.$root.$emit('immobile-modal')
    },
    editImmobile(item) {
      this.$root.$emit('immobile-modal', item)
    },
    attachments(item) {
      this.$root.$emit('immobile-attachment', item)
    },
    immobileReport(item) {
      this.$router.push({ name: 'immobile.report', params: { id: item.id } })
    },
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),
  },

  watch: {
    search(val) {
      if (val) {
        this.result = this.fuseInstance.search(val).map(i => i.item)
      } else {
        this.result = this.immobiles
      }
    },
  },

  created() {
    this.getImmobiles()
  },
}
</script>

<style lang="scss" scoped>
.v-card__title {
  text-align: center !important;
  display: inherit;
}
</style>
