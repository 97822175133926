import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import 'sweetalert2/dist/sweetalert2.min.css'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import VuetifyMask from 'vuetify-mask'
import Toast from './utils/Toast'
import VueSweetalert2 from 'vue-sweetalert2'

Vue.use(VueSweetalert2)
Vue.use(VuetifyMask)
Vue.config.productionTip = false
Vue.prototype.$toast = Toast

// Filters
import './plugins/filters.js'


// Components
import TooltipedBtn from './components/global/TooltipedBtn.vue'
Vue.component('TooltipedBtn', TooltipedBtn)


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
