<template>
  <v-card flat class="pa-4">
    <h3 class="mb-3">Assinatura digital</h3>
    <div class="d-flex gap-4">
      <div class="flex-grow-1">
        <v-select
          v-model="safe"
          :items="safes"
          :disabled="!loaded"
          label="Cofre"
          outlined
          item-text="name-safe"
          item-value="uuid_safe"
          dense
          :loading="loading"
        ></v-select>
      </div>
      <TooltipedBtn
        title="Salvar"
        :disabled="!loaded || safe === defaultSafe"
        :loading="loading && loaded"
        color="success"
        icon
        @click="save"
      >
        <v-icon>mdi-content-save</v-icon>
      </TooltipedBtn>
    </div>
  </v-card>
</template>

<script>
import DOCSIGN from '@/services/docsign'

export default {
  data: () => ({
    loaded: false,
    loading: false,
    safe: null,
    defaultSafe: null,
    safes: [],
  }),
  computed: {},
  methods: {
    async save() {
      try {
        this.loading = true
        await DOCSIGN.safe.setDefault(this.safe)
        this.defaultSafe = this.safe
        this.loading = false
      } catch (error) {
        alert('Erro ao salvar o cofre')
      }
    },
    async getSafes() {
      try {
        this.loading = true
        const { data } = await DOCSIGN.safe.getAll()
        this.defaultSafe = data.find(s => s.default)?.uuid_safe
        this.safe = data.find(s => s.default)?.uuid_safe
        this.safes = data
        this.loaded = true
        this.loading = false
      } catch (error) {
        alert('Erro ao buscar os cofres')
      }
    },
  },
  mounted() {
    this.getSafes()
  },
}
</script>

<style>
</style>