<template>
  <div>
    <v-card flat class="mt-1">
      <v-form class="multi-col-validation">
        <div class="pa-3">
          <v-card-text>
            <v-card-title class="px-0 pt-0">
              <v-text-field
                class="pt-0"
                v-model="search"
                append-icon="mdi-magnify"
                label="Pesquisar..."
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="services"
              :search="search"
              :header-props="{ sortByText: 'Ordenar por:' }"
              hide-default-footer
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-btn
                    color="primary"
                    dark
                    class="mb-4"
                    style="margin-left: -17px"
                    @click="$emit('service-modal')"
                    v-if="hasPermission(1073741824)"
                  >
                    Adicionar Serviço
                  </v-btn>
                </v-toolbar>
              </template>
              <template v-slot:[`item.active`]="{ item }">
                <v-chip label small outlined :color="item.active ? 'success' : 'danger'">
                  {{ item.active ? 'Ativo' : 'Inativo' }}
                </v-chip>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <TooltipedBtn title="Editar" icon @click="editService(item)" v-if="hasPermission(4)">
                  <v-icon>mdi-pencil-outline</v-icon>
                </TooltipedBtn>
                <!-- <v-btn icon @click="deleteUser(item)" v-if="hasPermission(8) && item.id != user.id">
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn> -->
              </template>
              <template v-slot:no-data>
                <v-btn color="primary" @click="search = ''">Resetar</v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </div>
      </v-form>
    </v-card>
    <service-modal @service-modal="getAll" @success="getAll" />
  </div>
</template>

<script>
import SERVICES from '@/services/services'
import ServiceModal from '@/components/modals/ServiceModal'
import { mapGetters, mapActions } from 'vuex'
export default {
  components: { ServiceModal },
  data: () => ({
    loading: false,
    search: '',
    services: [],
    headers: [
      { text: 'Nome', value: 'name', sortable: true, align: 'start' },
      { text: 'Status', value: 'active', sortable: true, align: 'center' },
      { text: 'Ações', value: 'actions', sortable: false, align: 'end' },
    ],
  }),

  methods: {
    ...mapActions('auth', ['getPermissions']),
    editService(service) {
      this.$emit('service-modal', service)
    },
    async getAll() {
      try {
        this.loading = true
        const response = await SERVICES.getAll()
        this.services = response.services
        this.loading = false
      } catch (e) {
        console.log(e)
        this.$toast.fire({
          icon: 'error',
          title: e.message || 'Erro ao buscar usuários',
        })
        this.$router.push({ name: 'dashboard' })
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['hasPermission', 'user']),
  },

  mounted() {
    this.getAll()
  },
}
</script>

<style lang="scss" scoped>
.v-card__title {
  text-align: center !important;
  display: inherit;
}
</style>
