<template>
  <v-dialog v-model="dialog" max-width="500px" :persistent="loading" @click:outside="close">
    <v-card class="pa-4">
      <v-card-title class="justify-center text-center">Tem certeza que deseja <br />excluir este anexo?</v-card-title>
      <v-card-actions class="mt-4">
        <v-spacer></v-spacer>
        <v-btn color="primary" class="mr-2 mt-2" outlined @click="close" :disabled="loading">Cancelar</v-btn>
        <v-btn color="primary" class="mt-2" @click="destroy" :loading="loading">Sim</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import IMMOBILE from '@/services/immobile'

export default {
  data: () => ({
    dialog: false,
    loading: false,
    valid: true,
    attachment: null,
  }),
  methods: {
    async destroy() {
      try {
        this.loading = true

        var response = await IMMOBILE.attachment.destroy(this.immobile.id, this.attachment.id)

        this.$emit('success', { id: this.attachment.id })
        this.loading = false
        this.close()
      } catch (error) {
        this.loading = false
        this.$toast.fire({
          icon: 'error',
          title: error.message || 'Erro ao excluir anexo',
        })
        this.$emit('error', error)
      }
    },
    open(attachment) {
      this.attachment = attachment
      this.dialog = true
    },
    close() {
      if (this.loading) return
      this.dialog = false
      this.loading = false
    },
  },
  props: {
    immobile: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    this.$parent.$on('delete-attachment', this.open)
  },
}
</script>

<style></style>
