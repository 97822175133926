<template>
  <v-dialog v-model="dialog" max-width="auto" scrollable fullscreen hide-overlay>
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ client.id ? 'Editar' : 'Criar' }} Cliente</span>
      </v-card-title>

      <v-card-text>
        <v-container fluid>
          <v-form v-model="valid">
            <v-row>
              <v-col cols="12">
                <v-radio-group v-model="client.type" :mandatory="true" row hide-details class="pt-0 mt-0">
                  <v-radio label="Pessoa Física" value="PF"></v-radio>
                  <v-radio label="Pessoa Jurídica" value="PJ"></v-radio>
                </v-radio-group>
              </v-col>

              <v-col cols="12" v-if="client.type == 'PF'">
                <v-row>
                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="client.name"
                      :rules="[v => !!v || 'Obrigatório']"
                      :disabled="loading"
                      label="Nome Completo"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="client.nationality"
                      :rules="[v => !!v || 'Obrigatório']"
                      :disabled="loading"
                      label="Nacionalidade"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6" md="3">
                    <v-select
                      v-model="client.maritalStatus"
                      :rules="[v => !!v || 'Obrigatório']"
                      :disabled="loading"
                      label="Estado Civil"
                      outlined
                      dense
                      hide-details
                      item-text="text"
                      item-value="value"
                      :items="maritalStatus"
                    ></v-select>
                  </v-col>

                  <v-col cols="6" md="3">
                    <v-text-field
                      v-model="client.activity"
                      :rules="[v => !!v || 'Obrigatório']"
                      :disabled="loading"
                      label="Atividade"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6" md="3">
                    <v-text-field-cpf
                      v-model="client.cpf"
                      label="CPF"
                      :properties="{
                        disabled: loading,
                        outlined: true,
                        dense: true,
                        hideDetails: true,
                        rules: [v => !!v || 'Obrigatório'],
                      }"
                      :options="{ outputMask: '###.###.###-##', empty: null, applyAfter: false, alphanumeric: false }"
                    />
                  </v-col>

                  <v-col cols="6" md="3">
                    <v-text-field-simplemask
                      v-model="client.rg"
                      label="RG"
                      :properties="{
                        disabled: loading,
                        outlined: true,
                        dense: true,
                        hideDetails: true,
                        rules: [v => !!v || 'Obrigatório'],
                      }"
                      :options="{
                        inputMask: '##.###.###-#',
                        outputMask: '##.###.###-#',
                        empty: null,
                        applyAfter: false,
                      }"
                    />
                  </v-col>

                  <v-col cols="6" md="2">
                    <v-text-field
                      v-model="client.rgEmissor"
                      :rules="[v => !!v || 'Obrigatório']"
                      :disabled="loading"
                      label="Emissor do RG"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="2">
                    <v-text-field
                      v-model="client.birthDate"
                      :disabled="loading"
                      type="date"
                      label="Data de Nascimento"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" v-if="client.type == 'PJ'">
                <v-row>
                  <v-col cols="12" md="3">
                    <v-text-field-cnpj
                      v-model="client.cnpj"
                      label="CNPJ"
                      :properties="{
                        disabled: cnpjLoading,
                        loading: cnpjLoading,
                        outlined: true,
                        dense: true,
                        hideDetails: true,
                        rules: [v => !!v || 'Obrigatório'],
                      }"
                      :options="{
                        outputMask: '##.###.###/####-##',
                        empty: null,
                        applyAfter: false,
                        alphanumeric: false,
                      }"
                    />
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="client.name"
                      :rules="[v => !!v || 'Obrigatório']"
                      :disabled="loading || cnpjLoading"
                      label="Razão Social"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="client.fantasyName"
                      :rules="[v => !!v || 'Obrigatório']"
                      :disabled="loading || cnpjLoading"
                      label="Nome Fantasia"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="client.activity"
                      :rules="[v => !!v || 'Obrigatório']"
                      :disabled="loading || cnpjLoading"
                      label="Atividade"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="client.nationality"
                      :rules="[v => !!v || 'Obrigatório']"
                      :disabled="loading"
                      label="Nacionalidade"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="3">
                    <client-selector
                      v-model="client.responsible"
                      label="Responsável"
                      outlined
                      dense
                      hide-details
                    ></client-selector>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" md="9">
                <address-search
                  v-model="client.address"
                  :defaultValue="defaultAddress"
                  :autoRun="true"
                ></address-search>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="client.complement"
                  label="Complemento"
                  :disabled="loading"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  v-model="client.email"
                  :rules="[v => !!v || 'Obrigatório']"
                  :disabled="loading"
                  type="email"
                  label="E-mail"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field-simplemask
                  v-model="client.phone"
                  label="Telefone Principal"
                  :properties="{
                    disabled: loading,
                    outlined: true,
                    hit: 'As notificações serão enviadas para este número',
                    dense: true,
                    hideDetails: 'auto',
                    rules: [v => !!v || 'Obrigatório'],
                  }"
                  :options="{
                    inputMask: '(##) #####-####',
                    outputMask: '(##) #####-####',
                    empty: null,
                    applyAfter: false,
                  }"
                />
              </v-col>
              <v-cols cols="12" md="9">
                <div class="mt-3 d-flex flex-column gap-2">
                  <div v-for="(extraPhone, i) in client.extraPhones" :key="i" class="d-flex gap-1">
                    <v-text-field
                      v-model="client.extraPhones[i].name"
                      :rules="[v => !!v || 'Obrigatório', v => v.length <= 20 || 'Máximo de 20 caracteres']"
                      :disabled="loading"
                      label="Tipo"
                      placeholder="Residecial, Comercial, etc"
                      outlined
                      dense
                      hide-details
                    />
                    <v-text-field-simplemask
                      v-model="client.extraPhones[i].phone"
                      label="Telefone"
                      :properties="{
                        placeholder: '(00) 00000-0000',
                        disabled: loading,
                        outlined: true,
                        dense: true,
                        hideDetails: true,
                        rules: [v => !!v || 'Obrigatório'],
                      }"
                      :options="{
                        inputMask: '(##) #####-####',
                        outputMask: '(##) #####-####',
                        empty: null,
                        applyAfter: false,
                      }"
                    />
                    <v-btn icon @click="client.extraPhones.splice(i, 1)">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                  <v-btn @click="client.extraPhones.push({ name: '', phone: '' })" outlined> Adicionar Telefone </v-btn>
                </div>
              </v-cols>
              <v-col cols="12" class="pa-0"></v-col>

              <v-col cols="auto">
                <label>Status:</label>
                <v-radio-group v-model="client.active" :mandatory="true" row hide-details class="pt-0">
                  <v-radio label="Ativo" :value="true"></v-radio>
                  <v-radio label="Inativo" :value="false"></v-radio>
                </v-radio-group>
              </v-col>

              <v-col cols="auto">
                <label>Gerar Senha:</label>
                <v-checkbox v-model="client.createPassword" label="Enviar Link" class="pt-0" hide-details></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions class="px-8 pb-8">
        <v-spacer></v-spacer>
        <v-btn color="primary" class="mr-2 mt-2" outlined :disabled="loading" @click="close">Cancelar</v-btn>
        <v-btn color="primary" class="mt-2" @click="save" :disabled="!valid" :loading="loading">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AddressSearch from '../global/AddressSearch.vue'
import CLIENT from '@/services/client'
import ClientSelector from '../global/ClientSelector.vue'
import ConsultarCNPJ from 'consultar-cnpj'
import moment from 'moment'
const defaultData = () => ({
  name: '',
  type: 'PF',
  cpf: '',
  rg: '',
  cnpj: '',
  activity: '',
  address: null,
  complement: null,
  phone: '',
  active: true,
  createPassword: false,
  extraPhones: [],
})
export default {
  components: { AddressSearch, ClientSelector },
  data() {
    return {
      dialog: false,
      loading: false,
      cnpjLoading: false,
      valid: false,
      client: defaultData(),
      defaultAddress: null,
      maritalStatus: [
        {
          value: 'Solteiro',
          text: 'Solteiro',
        },
        {
          value: 'Casado',
          text: 'Casado',
        },
        {
          value: 'Separado',
          text: 'Separado',
        },
        {
          value: 'Divorciado',
          text: 'Divorciado',
        },
        {
          value: 'Viuvo',
          text: 'Viuvo',
        },
        {
          value: 'UniaoEstavel',
          text: 'União Estável',
        },
      ],
    }
  },
  methods: {
    async getCnpj() {
      try {
        this.cnpjLoading = true
        let cnpj = this.client.cnpj.replace(/[^\d]+/g, '')
        let response = await ConsultarCNPJ(cnpj)

        if (!this.client.fantasyName) this.client.fantasyName = response.estabelecimento.nome_fantasia
        if (!this.client.name) this.client.name = response.razao_social
        if (!this.client.activity) this.client.activity = response.estabelecimento.atividade_principal.descricao
        if (!this.client.complement) this.client.complement = response.estabelecimento.complemento
        if (!this.client.address)
          this.defaultAddress = `${response.estabelecimento.tipo_logradouro} ${response.estabelecimento.logradouro}, ${response.estabelecimento.numero} - ${response.estabelecimento.cep}`
        if (!this.client.email) this.client.email = response.estabelecimento.email
      } catch (e) {
        this.$toast.fire({
          icon: 'error',
          title: e.detalhes || e.titulo || e.message || 'Ocorreu um erro ao buscar o CNPJ',
        })
      } finally {
        this.cnpjLoading = false
      }
    },
    async save() {
      try {
        // throw new Error('Não implementado')
        this.loading = true
        var data = {
          ...this.client,
          birthDate: this.client.birthDate ? new Date(this.client.birthDate).toISOString() : null,
          address: {
            ...this.client.address,
            complement: this.client.complement,
          },
          complement: undefined,
        }

        var response
        if (!this.client.id) {
          if (!this.client.address) throw new Error('Endereço não informado')
          response = await CLIENT.create(data)
        } else {
          response = await CLIENT.update(this.client.id, data)
        }
        this.$emit('success', response.client)
        this.close()
      } catch (error) {
        console.log(error)
        this.$toast.fire({
          icon: 'error',
          title: error.message || 'Erro ao salvar imóvel',
        })
        this.$emit('error', error)
      } finally {
        this.loading = false
      }
    },
    open(data) {
      this.dialog = true
      this.client = Object.assign(defaultData(), data)
      if (data) {
        this.defaultAddress = `${data.address.street}, ${data.address.number} - ${data.address.zipCode} - ${data.address.city} - ${data.address.state}`
        this.client.complement = data.address.complement
        if (data.birthDate) this.client.birthDate = moment(data.birthDate).format('YYYY-MM-DD')
      }
    },
    close() {
      this.loading = false
      this.dialog = false
      this.client = defaultData()
    },
  },
  watch: {
    'client.type'(val) {
      if (val === 'PF') {
        this.client.cnpj = ''
      } else {
        this.client.cpf = ''
        this.client.rg = ''
      }
    },
    'client.cnpj': {
      handler(val) {
        if (val && val.length >= 18) {
          this.getCnpj(val)
        }
      },
      immediate: true,
    },
  },
  created() {
    this.$parent.$on(this.componentName, this.open)
  },
  props: {
    componentName: {
      type: String,
      default: 'client-modal',
    },
  },
}
</script>
