<template>
  <div>
    <v-card flat class="mt-1" :loading="loading">
      <v-form class="multi-col-validation">
        <div class="pa-3">
          <v-card-text>
            <v-card-title class="px-0 pt-0">
              <v-text-field
                class="pt-0"
                v-model="search"
                append-icon="mdi-magnify"
                label="Pesquisar..."
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="payments"
              :search="search"
              sort-by="validity"
              :hide-default-footer="payments.length < 10"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-btn
                    @click="openPaymentModal"
                    v-if="hasPermission(1048576)"
                    color="primary"
                    dark
                    class="mb-4"
                    style="margin-left: -17px"
                    :disabled="loadingDownload || loading || !hasContractActive"
                  >
                    Gerar Pagamento
                  </v-btn>
                </v-toolbar>
              </template>

              <template v-slot:[`item.validity`]="{ item }">
                {{ item.validity | date }}
              </template>
              <template v-slot:[`item.value`]="{ item }">
                {{ item.value | money }}
              </template>
              <template #[`item.status`]="{ item }">
                <v-chip small :color="item.s.color" outlined label class="font-weight-medium">
                  {{ item.s.text }}
                </v-chip>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  icon
                  @click="downloadItem(item)"
                  :loading="loadingDownload == item.id"
                  :disabled="!!loadingDownload || loading || ['Paid', 'Canceled'].includes(item.status)"
                >
                  <v-icon> mdi-tray-arrow-down </v-icon>
                </v-btn>
                <v-btn
                  icon
                  :disabled="!!loadingDownload || loading || ['Paid', 'Canceled'].includes(item.status)"
                  v-if="hasPermission(2097152)"
                  @click="openPaymentModal(item)"
                >
                  <v-icon> mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  @click="openPaymentDestroy(item)"
                  icon
                  :disabled="!!loadingDownload || loading || ['Paid', 'Canceled'].includes(item.status)"
                  v-if="hasPermission(4194304)"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </template>
              <template v-slot:no-results>
                <v-btn color="primary" @click="search = ''">Resetar</v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </div>
      </v-form>
    </v-card>
    <boleto />
    <payment-modal @success="$emit('refresh')" />
    <payment-delete @success="$emit('refresh')" />
  </div>
</template>

<script>
import moment from 'moment'
import rentalService from '@/services/rental'
import Boleto from '@/components/modals/Boleto.vue'
import PaymentModal from '@/components/modals/PaymentModal.vue'
import { mapGetters } from 'vuex'
import PaymentDelete from '@/components/modals/PaymentDelete.vue'

export default {
  components: { Boleto, PaymentModal, PaymentDelete },
  data: () => ({
    loadingDownload: null,
    search: '',
    headers: [
      { text: 'Data Vencimento', value: 'validity', sortable: true },
      { text: 'Valor', value: 'value', sortable: true },
      { text: 'Descrição', value: 'description', sortable: false },
      { text: 'Situação', value: 'status', sortable: true, align: 'center' },
      { text: 'Boleto', value: 'actions', sortable: false, align: 'end' },
    ],
  }),
  methods: {
    openPaymentDestroy(data) {
      this.$emit('payment-delete', {
        payment: data,
        rental: this.rental,
      })
    },
    openPaymentModal(data) {
      this.$emit('payment-modal', {
        payment: data,
        rental: this.rental,
      })
    },
    async downloadItem(item) {
      try {
        this.loadingDownload = item.id
        let response = await rentalService.getBoleto(this.rental.id, item.id)
        console.log(response)
        this.$root.$emit('show-boleto', response)
      } catch (e) {
        this.$toast.fire({
          title: e.message || 'Erro ao gerar boleto',
          icon: 'error',
        })
      } finally {
        this.loadingDownload = null
      }
    },
    paymentStatus(payment) {
      if (payment.status == 'Paid') return { text: 'Pago', color: 'success' }
      if (payment.status == 'Canceled') return { text: 'Cancelado', color: 'secondary' }
      if (payment.status == 'Pending' && moment().isAfter(payment.validity)) return { text: 'Vencido', color: 'error' }
      return { text: 'Pendente', color: 'warning' }
    },
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
    payments() {
      let payments = []
      if (!this.rental) return payments
      this.rental.Contracts.forEach(c => {
        payments.push(...c.Payment)
      })

      payments = payments.map(p => {
        p.s = this.paymentStatus(p)
        return p
      })

      return payments
    },
    hasContractActive() {
      return this.rental.Contracts.some(contract => contract.status === 'active' || contract.status === 'pending')
    },
  },
  filters: {
    date(value) {
      return moment(value).format('DD/MM/YYYY')
    },
    money(value) {
      value = parseFloat(value)
      return value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
    },
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    rental: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.v-card__title {
  text-align: center !important;
  display: inherit;
}
</style>
