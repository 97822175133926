<template>
  <v-card outlined class="pa-4">
    <h4>Locações</h4>
    <v-data-table :items="rentals" :headers="headers" class="elevation-0">
      <template v-slot:item.status="{ item }">
        <v-chip :color="item.status === 'Ativo' ? 'success' : 'error'" dark>{{ item.status }}</v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" :to="`/locacoes/${item.id}`">
              <v-icon small>mdi-eye</v-icon>
            </v-btn>
          </template>
          <span>Visualizar locação</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import moment from 'moment'
export default {
  data: () => ({
    headers: [
      // Status
      {
        text: 'Status',
        align: 'start',
        sortable: false,
        value: 'status',
      },
      {
        text: 'Tipo',
        align: 'start',
        sortable: false,
        value: 'type',
      },
      // duration
      {
        text: 'Duração',
        align: 'start',
        sortable: false,
        value: 'duration',
      },
      // contratos
      {
        text: 'Contratos',
        align: 'start',
        sortable: false,
        value: 'contracts',
      },
      // pagamentos
      {
        text: 'Pagamentos',
        align: 'start',
        sortable: false,
        value: 'payments',
      },
      // Ações
      {
        text: 'Ações',
        align: 'end',
        sortable: false,
        value: 'actions',
      },
    ],
  }),
  methods: {},
  computed: {
    rentals() {
      return this.immobile.ImmobileRental.map(({ rental }) => {
        const status = rental.status === 'active' ? 'Ativo' : 'Inativo'
        const duration = `${moment(rental.startDate).format('MM/YYYY')} - ${moment(rental.endDate).format(
          'MM/YYYY',
        )} (${moment(rental.endDate).diff(moment(rental.startDate), 'months') + 1})`
        const contracts = rental.Contracts.length
        const payments = rental.Contracts.reduce(
          (acc, { Payment }) => {
            acc[0] += Payment.length
            acc[1] += Payment.reduce((acc, { value }) => acc + value, 0)
            return acc
          },
          [0, 0],
        )
        return {
          id: rental.id,
          status,
          duration,
          contracts,
          type: rental.type,
          payments: `${payments[1].toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })} (${payments[0]})`,
        }
      })
    },
  },
  props: {
    immobile: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style></style>
